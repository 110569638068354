body {
  min-height: 100%;
  z-index: -2;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background: url("bg.1b29cd45.jpg") top / cover no-repeat fixed;
  line-height: 20px;
  color: #9ea7b3 !important;
  margin: 0 !important;
  font-family: Open Sans, Arial, sans-serif !important;
  font-size: 13px !important;
  overflow-x: hidden !important;
}

a:hover {
  text-decoration: none !important;
}

#mapContainer {
  color: #444;
  height: 100%;
  background: #fff;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#logo {
  max-width: 191px;
  position: relative;
  top: -34px;
  left: 19px;
}

#logo-mobile {
  z-index: 0;
  position: absolute;
  top: -14px;
  left: -33px;
}

#logo h1, #logo-mobile h1 {
  letter-spacing: -1px;
  text-decoration: inherit;
  text-transform: uppercase;
  color: #fff;
  font-size: 30px;
  font-weight: 200;
}

#logo h1 span, #logo-mobile h1 span {
  background: #0003;
  border-radius: 3px;
  margin-left: 0;
  padding: 0 5px;
  font-size: 11px;
  position: relative;
  top: -3px;
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.row-fluid[class="span"] {
  margin-left: 0 !important;
}

.navbar-toggle {
  float: right;
  background-color: #0000;
  background-image: none;
  border: 1px solid #0000;
  margin-right: 15px;
  position: relative;
  color: #fff !important;
  border-radius: 4px !important;
  margin-top: 2px !important;
  margin-bottom: 15px !important;
  padding: 0 16px !important;
  font-size: 31px !important;
}

.nav > li > a:hover, .nav > li > a:focus {
  text-decoration: none;
  background-color: #0000 !important;
}

.navbar-inverse .nav .active > a, .navbar-inverse .nav .active > a:hover, .navbar-inverse .nav .active > a:focus {
  color: #fff;
  background-color: #111;
  border-radius: 5px !important;
}

.navbar-top {
  position: relative;
  top: 10px;
}

.navbar-nav > li > a {
  color: #f5f5f5 !important;
  font-size: 13px !important;
}

.nav-pills > li > a {
  color: #999;
}

.navbar {
  border: 1px solid #0000;
  min-height: 65px !important;
  margin-top: 10px !important;
  margin-bottom: 0 !important;
  position: relative !important;
}

.navbar-inner {
  min-height: 60px !important;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #0000 !important;
}

.dropdown-menu {
  float: left;
  min-width: 160px;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  margin: 3px 0 0;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2147483647 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #00000026 !important;
}

.dropdown-wrap:after {
  content: "";
  width: 0;
  z-index: 1;
  border: 13px solid #0000;
  border-top: 0 solid #fff;
  border-bottom-color: #fff;
  margin-left: -15px;
  display: block;
  position: absolute;
  top: -10px;
  left: 10.5%;
}

.dropdown-setting {
  position: absolute;
  width: 165px !important;
  border-bottom: 1px solid #0000001a !important;
  border-left: 1px solid #0000001a !important;
  border-right: 1px solid #0000001a !important;
  border-radius: 4px !important;
  margin-top: 14px !important;
}

.dropdown-setting:after {
  content: "";
  width: 0;
  z-index: 1;
  border: 13px solid #0000;
  border-top: 0 solid #fff;
  border-bottom-color: #fff;
  margin-left: -15px;
  display: block;
  position: absolute;
  top: -10px;
  left: 80%;
}

.dropdown-menu.dropdown-wrap b {
  color: #fff;
  background: #a8bdcf;
  border-radius: 3px;
  margin-top: 0;
  padding: 2px 4px;
  font-size: 11px;
  font-weight: normal;
  position: absolute;
  right: 10px;
}

.dropdown-menu.dropdown-wrap div {
  color: #fff;
  text-align: center;
  width: 230px;
  cursor: pointer;
  background: #a8bdcf;
  padding: 6px;
  font-size: 13px;
  position: relative;
  bottom: 2px;
  left: 0;
}

.dropdown-menu.dropdown-wrap div:hover {
  background: #0db8df;
}

.inlinebar > canvas {
  text-align: center;
  margin: 0 auto;
}

.alert-warning {
  color: #fbb44c !important;
  background-color: #fbb44c1a !important;
}

.alert-danger {
  color: #ff6b6be6 !important;
  background-color: #ff6b6b1a !important;
}

.alert-success {
  color: #45b6b0 !important;
  background-color: #45b6b01a !important;
}

.alert-info {
  color: #65c3df !important;
  background-color: #65c3df1a !important;
}

.progress {
  height: 15px !important;
  background-color: #f5f5f5 !important;
  border-radius: 4px !important;
  margin-bottom: 15px !important;
  overflow: hidden !important;
  box-shadow: inset 0 1px #0003 !important;
}

.progress-bar {
  font-size: 10px !important;
  line-height: 14px !important;
}

.progress-bar-success {
  background-color: #45b6b0 !important;
}

.progress-bar-info {
  background-color: #65c3df !important;
}

.progress-bar-warning {
  background-color: #fbb44c !important;
}

.progress-bar-danger {
  background-color: #ff6b6b !important;
}

.label, .badge {
  color: #fff;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: .25em;
  padding: 2px 4px;
  font-family: Open Sans;
  font-size: 11px;
  font-weight: normal;
  line-height: 14px;
  display: inline-block;
  background-color: #a8bdcf !important;
}

.label-success {
  background-color: #45b6b0 !important;
}

.label-warning {
  background-color: #fbb44c !important;
}

.label-important {
  background-color: #ff6b6b !important;
}

.label-info {
  background-color: #65c3df !important;
}

.label-inverse {
  background-color: #888 !important;
}

.setting-wrap {
  width: 200px;
  min-height: 100px;
  margin: 30px;
}

.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 0;
  font-weight: normal;
  line-height: 1.42857;
  display: inline-block;
  border-color: #c7d5e0 !important;
  padding: 6px 12px !important;
  font-size: 12px !important;
}

.btn-success {
  color: #fff !important;
  background-color: #45b6b0 !important;
  border-color: #45b6b0 !important;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
  color: #fff !important;
  background-color: #3da09a !important;
  border-color: #3da09a !important;
}

.btn-info {
  color: #fff !important;
  background-color: #65c3df !important;
  border-color: #65c3df !important;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
  color: #fff !important;
  background-color: #60b8d1 !important;
  border-color: #60b8d1 !important;
}

.btn-warning {
  color: #fff !important;
  background-color: #fbb44c !important;
  border-color: #fbb44c !important;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
  color: #fff !important;
  background-color: #eda849 !important;
  border-color: #eda849 !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #ff6b6b !important;
  border-color: #ff6b6b !important;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
  color: #fff !important;
  background-color: #e86161 !important;
  border-color: #e86161 !important;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
  color: #fff !important;
  background-color: #9bafbf !important;
  border-color: #9bafbf !important;
}

.btn-default {
  color: #fff !important;
  background-color: #a8bdcf !important;
  border-color: #a8bdcf !important;
}

.well {
  min-height: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 19px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #f5f5f5 !important;
  border: none !important;
  font-size: 13px !important;
}

input:-webkit-autofill, textarea:-webkit-autofill {
  background: #fff !important;
}

.form-control:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #45b6b0 !important;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  border-color: none !important;
  background: none !important;
}

.dropdown-menu > li > a {
  clear: both;
  white-space: nowrap;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.42857;
  color: #777 !important;
  padding: 3px 30px !important;
  display: inline !important;
}

.dropdown-menu > li > a:hover {
  color: #39b3d7 !important;
}

.devider {
  font-family: BebasNeueRegular, Arial, Helvetica, sans-serif;
  font-size: 40px;
  box-shadow: 0 1px #4a6390;
  border-bottom: 3px solid #000 !important;
}

.divider-vertical {
  float: right;
  height: 60px;
  border-right: 2px solid #000;
  box-shadow: 2px 0 #4a6390;
}

.form-control {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  box-shadow: none !important;
  color: #333 !important;
  height: 31px !important;
  border: 1px solid !important;
  font-size: 13px !important;
}

.form-update {
  width: 100%;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  box-shadow: none !important;
  color: #c7d5e0 !important;
  height: 70px !important;
  border: 1px solid !important;
  font-size: 13px !important;
}

.panel {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  border: 0 solid #0000 !important;
  box-shadow: 0 2px #0000000d !important;
}

.carousel-control.right, .carousel-control.left {
  background: none !important;
}

.carousel-control {
  color: #fff !important;
  text-shadow: none !important;
}

.modal-backdrop {
  height: 100%;
  inset: 0;
  width: 100% !important;
  z-index: 0 !important;
  background-color: #0000 !important;
  position: fixed !important;
}

.list-group-item {
  background-color: #ffffff1a !important;
  border: 1px solid #eee !important;
}

.theme-bg {
  margin: 0 5px !important;
}

.theme-bg div {
  float: left;
  display: inline-block;
}

#button-bg {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: url("small-bg5.e3e9a99d.jpg") 0 0 / cover;
  border: 4px solid #fff;
  border-radius: 50%;
}

#button-bg2 {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: url("small-bg2.88329f20.jpg") 0 0 / cover;
  border: 4px solid #fff;
  border-radius: 50%;
}

#button-bg3 {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: url("small-bg.a396c12b.jpg") 0 0 / cover;
  border: 4px solid #fff;
  border-radius: 50%;
}

#button-bg5 {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: url("giftly.c8889bee.png") 0 0 / cover;
  border: 4px solid #fff;
  border-radius: 50%;
}

#button-bg6 {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: #2c3e50;
  border: 4px solid #fff;
  border-radius: 50%;
}

#button-bg4 {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: #252d3b;
  border: 4px solid #fff;
  border-radius: 50%;
}

#button-bg7 {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: url("bg3.d73d3b2e.png") 0 0 / cover;
  border: 4px solid #fff;
  border-radius: 50%;
}

#button-bg8 {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: url("small-bg8.54ce7f75.jpg") 0 0 / cover;
  border: 4px solid #fff;
  border-radius: 50%;
}

#button-bg9 {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: url("small-bg9.130bdab8.jpg") 0 0 / cover;
  border: 4px solid #fff;
  border-radius: 50%;
}

#button-bg10 {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: url("small-bg10.70cc1add.jpg") 0 0 / cover;
  border: 4px solid #fff;
  border-radius: 50%;
}

#button-bg11 {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: url("small-bg11.769af5bf.jpg") 0 0 / cover;
  border: 4px solid #fff;
  border-radius: 50%;
}

#button-bg12 {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: url("small-bg12.95658b2b.jpg") 0 0 / cover;
  border: 4px solid #fff;
  border-radius: 50%;
}

#button-bg13 {
  height: 25px;
  width: 25px;
  cursor: pointer;
  background: url("small-bg13.a6517d30.jpg") 0 0 / cover;
  border: 4px solid #fff;
  border-radius: 50%;
}

.header {
  z-index: 9999;
  width: 100%;
  height: 60px;
  background: #000;
  position: fixed;
  top: 0;
}

.nav-pad-top {
  margin: 13px 0 0;
}

img.logo {
  height: 38px;
  position: relative;
  top: 0;
  left: 0;
}

img.ikon-check {
  height: 24px;
  width: 24px;
  margin: 0 8px 0 0;
}

img.ikon {
  height: 24px;
  width: 24px;
}

.icon-menu:hover {
  color: #0db8df;
}

.noft-red {
  color: #fff;
  text-align: center;
  width: 20px;
  background: #ea3f3f;
  border-radius: 26px;
  padding: 0;
  font-size: 10px;
  position: absolute;
  top: 5px;
  left: 25px;
}

.noft-green {
  color: #fff;
  text-align: center;
  width: 20px;
  background: #4ac4bc;
  border-radius: 26px;
  padding: 0;
  font-size: 10px;
  position: absolute;
  top: 5px;
  left: 25px;
}

.noft-yellow {
  color: #fff;
  text-align: center;
  width: 20px;
  background: #ffa200;
  border-radius: 26px;
  padding: 0;
  font-size: 10px;
  position: absolute;
  top: 5px;
  left: 25px;
}

.noft-blue {
  color: #fff;
  text-align: center;
  background: #0db8df;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 9px;
  position: relative;
  top: 0;
  left: 0;
  float: right !important;
}

.noft-orange {
  color: #fff;
  text-align: center;
  background: #ffa200;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 9px;
  position: relative;
  top: 0;
  left: 0;
  float: right !important;
}

.noft-blue-number {
  color: #fff;
  height: 25px;
  text-align: center;
  width: 25px;
  background: #0db8df;
  border-radius: 50%;
  padding: 0;
  font-size: 11px;
  position: relative;
  top: 0;
  left: 0;
  float: right !important;
}

.noft-purple-number {
  color: #fff;
  height: 25px;
  text-align: center;
  width: 25px;
  background: #ff6b6b;
  border-radius: 50%;
  padding: 0;
  font-size: 11px;
  position: relative;
  top: 0;
  left: 0;
  float: right !important;
}

.admin-pic {
  float: left;
  height: 45px;
  width: 45px;
  border: 2px solid #fffc;
  margin-top: -12px;
  margin-left: -15px;
  margin-right: 19px;
  display: inline-block;
}

.noft-icon {
  color: #fff;
  float: left;
  height: 25px;
  text-align: center;
  width: 25px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 23px;
  position: relative;
  left: -13px;
}

.img-msg {
  color: #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  position: relative;
  top: 0;
  left: -17px;
}

#search {
  float: right;
}

#search input[type="text"] {
  width: 150px;
  background: #fff url("search-dark.2a63801c.png") 14px 10px no-repeat;
  border: 0;
  border-radius: 4px;
  padding: 6px 15px 6px 35px;
  color: #9ea7b3 !important;
}

#search input[type="text"]:focus {
  width: 200px;
  color: #fff !important;
}

.search {
  width: 100%;
  padding: 10px 15px 10px 60px;
}

input[type="text"] {
  color: #fff;
}

.search-menu {
  border-radius: 4px;
  margin: 0 10px;
}

.dark {
  background: url("search-white.db48b213.png") 36px 12px no-repeat;
  border-radius: 4px;
  margin-top: -18px;
  padding: 0 10px;
}

.dark input[type="text"] {
  color: #fff;
  background: #0000001a;
  border: none;
  border-radius: 4px;
}

input[type="search"] {
  width: 55px;
  border: 1px solid #ccc;
  border-radius: 10em;
  padding: 9px 10px 9px 32px;
  font-family: inherit;
  font-size: 100%;
}

input[type="search"]:focus {
  width: 130px;
  background-color: #fff;
  border-color: #6dcff6;
  box-shadow: 0 0 5px #6dcff680;
}

#demo-b input[type="search"] {
  color: #0000;
  cursor: pointer;
  width: 15px;
  padding-left: 10px;
}

#demo-b input[type="search"]:focus {
  color: #000;
  cursor: auto;
  width: 130px;
  background-color: #fff;
  padding-left: 32px;
}

.search-hover {
  display: block;
  position: absolute;
  top: 45px;
  left: 200px;
  right: -100px;
}

input {
  outline: none;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}

input::-webkit-search-decoration, input::-webkit-search-cancel-button {
  display: none;
}

input[type="search"] {
  background: #0000004d url("search-white.db48b213.png") 18px no-repeat;
  border: 0 solid #ddd;
  border-radius: 0;
  padding: 9px 10px 14px 32px;
  transition: all .1s;
}

input[type="search"]:focus {
  width: 300px;
  background-color: #252d3b;
  border-color: #66cc75;
  border-radius: 0 4px 4px 0;
  margin-right: 100px;
}

input:-moz-placeholder {
  color: #999;
}

input::-webkit-input-placeholder {
  color: #999;
}

#demo-2 input[type="search"] {
  color: #0000;
  cursor: pointer;
  width: 50px;
  padding-left: 10px;
}

#demo-2 input[type="search"]:hover {
  background-color: #252d3b;
}

#demo-2 input[type="search"]:focus {
  width: 200px;
  color: #252d3b;
  cursor: auto;
  background: #fff url("search-dark.2a63801c.png") 18px no-repeat;
  border: 1px solid #ddd;
  padding-top: 9px;
  padding-left: 50px;
  box-shadow: none !important;
}

#demo-2 input::-webkit-input-placeholder {
  color: #0000;
}

.map-frame {
  border: 1px solid #ccc;
  padding: 0;
}

.arrival {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.table-information {
  width: 50%;
  position: fixed;
  top: 20px;
  right: 15px;
}

.toogle-menu {
  color: #fff;
  cursor: pointer;
  height: 22px;
  width: 18px;
  background: #000;
  border-radius: 0 5px 5px 0;
  padding: 6px;
}

#tree-wrap {
  height: 470px;
  width: 100%;
  overflow-y: none;
  overflow-x: none;
}

#ticker {
  height: 80px;
  width: 100%;
  list-style-type: none;
  position: relative;
  top: 25px;
  right: 27px;
  overflow: hidden;
}

#ticker li {
  color: #f4af0b;
  text-align: center;
  padding: 22px;
  font-family: BebasNeueRegular, Arial, Helvetica, sans-serif;
  font-size: 80px;
}

.ticker2 {
  text-shadow: 1px 1px #000;
  margin: 0;
  padding: 0;
  list-style-type: none;
  top: 38px;
  left: 0;
  height: 100px !important;
  position: relative !important;
}

.ticker2 li {
  color: #f4af0b;
  font-family: BebasNeueRegular, Arial, Helvetica, sans-serif;
  font-size: 60px;
}

#fade {
  font-weight: normal;
  list-style-type: none;
  position: relative;
  left: -15px;
}

#fade li {
  text-align: left;
  margin: 0;
  padding: 5px 0 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 26px;
}

.info-list {
  text-transform: uppercase;
  background: -o-linear-gradient(top, #5272ab 0%, #2e3d59 1%, #0d1832 100%);
  background: -ms-linear-gradient(top, #5272ab 0%, #2e3d59 1%, #0d1832 100%);
  filter: progid: DXImageTransform. Microsoft. gradient(startColorstr= "#5272ab", endColorstr= "#0d1832", GradientType= 0);
  background: linear-gradient(#5272ab 0%, #2e3d59 1%, #0d1832 100%);
  border: 1px solid #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: bold;
  text-align: center !important;
}

.info-list a {
  text-align: center;
}

#jalur {
  font-weight: bold;
  list-style-type: none;
  position: relative;
  left: -10px;
  height: 80px !important;
}

#jalur li {
  color: #f4af0b;
  text-align: center;
  text-shadow: 0 2px #000;
  margin: 0;
  padding: 100px 0;
  font-family: BebasNeueRegular, Arial, Helvetica, sans-serif;
  font-size: 210px;
}

.subtittle {
  text-transform: none;
  text-shadow: 0 2px #000;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  position: relative;
  top: -81px;
  left: 60px;
  font-size: 14px !important;
}

.clock-ticker {
  height: 45px;
  width: 7%;
  z-index: 1031;
  background: #f4af0b;
  border-left: 8px solid #062358;
  position: fixed;
  bottom: 0;
  right: 0;
}

@font-face {
  font-family: BebasNeueRegular;
  font-weight: normal;
  font-style: normal;
}

.clock {
  position: fixed;
  top: 15px;
  right: 10px;
}

.date-top {
  float: left;
  border-right: 1px solid #0006;
  margin-left: -30px;
  padding-right: 15px;
  box-shadow: 1px 0 #ffffff1a;
}

.date-top > li {
  display: inline;
}

@-webkit-keyframes mymove {
  0% {
    opacity: 1;
    text-shadow: 0 0 20px #00c6ff;
  }

  50% {
    opacity: 0;
    text-shadow: none;
  }

  100% {
    opacity: 1;
    text-shadow: 0 0 20px #00c6ff;
  }
}

@-moz-keyframes mymove {
  0% {
    opacity: 1;
    text-shadow: 0 0 20px #00c6ff;
  }

  50% {
    opacity: 0;
    text-shadow: none;
  }

  100% {
    opacity: 1;
    text-shadow: 0 0 20px #00c6ff;
  }
}

.glyphicon {
  margin-right: 4px !important;
}

.pagination .glyphicon {
  margin-right: 0 !important;
}

.pagination a {
  color: #555;
}

.panel ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.news-item {
  border-bottom: 1px dotted #555;
  margin: 0;
  padding: 4px;
}

.panel-grid {
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  box-shadow: 0 1px 1px #0000000d;
}

.running-text {
  color: #fff;
  width: 50%;
  background: #0003;
  border: 1px solid #0003;
  border-radius: 30px;
  margin: 6px;
  padding: 10px 45px 0 20px;
  box-shadow: 0 1px #ffffff1a;
}

#nt-title {
  margin: 0;
}

#nt-title li {
  text-overflow: ellipsis;
  white-space: nowrap;
  list-style: none;
  overflow: hidden;
}

.digital {
  float: left;
  border-right: 1px solid #0006;
  margin-left: -25px;
  margin-right: -25px;
  padding-right: 15px;
  box-shadow: 1px 0 #ffffff1a;
}

.digital li {
  display: inline;
}

.meridiem {
  font-size: 10px;
}

.tittle-content-header > div {
  float: left;
  margin-right: 10px;
}

.tickercontainer {
  width: 100%;
  height: 45px;
  z-index: 999;
  background: #000;
  margin: 0;
  padding: 0;
  position: fixed;
  bottom: 0;
  overflow: hidden;
}

.tickercontainer .mask {
  height: 20px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  top: 13px;
  overflow: hidden;
}

ul.newsticker {
  font: bold 35px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}

ul.newsticker li {
  float: left;
  color: #fff;
  background-position: -2px -2px;
  background-repeat: no-repeat;
  margin: 0;
  padding-left: 25px;
  padding-right: 19px;
}

ul.newsticker a {
  white-space: nowrap;
  color: #fff;
  margin: 0 50px 0 0;
  padding: 0;
  font: bold 10px Verdana;
}

ul.newsticker span {
  margin: 0 10px 0 0;
}

#webticker2 {
  float: right;
}

#webticker2 li {
  float: right;
  margin: 0;
  padding-left: 15px;
}

.informasi-dtng {
  height: 100%;
  width: 30%;
  background: #0c1220;
  padding: 10px;
  position: fixed;
  top: 107px;
  right: 0;
}

.progress-table {
  color: #000;
  margin-top: 25px;
}

.table-center {
  text-align: center;
  font-family: BebasNeueRegular, Arial, Helvetica, sans-serif;
  font-size: 22px;
}

.table-halte {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-align: left !important;
}

.progress {
  box-shadow: none;
}

.progress.active .bar {
  border-radius: 5px !important;
}

.progress-bg {
  background: #f4af0b;
  border-radius: 5px;
  margin-top: 85px;
  padding: 5px;
}

img.bus-icon {
  float: right;
  position: relative;
  top: 1px;
  right: 3px;
  z-index: 5 !important;
}

.label {
  text-transform: capitalize !important;
  font-weight: normal !important;
}

#label ul {
  margin-left: -35px;
}

#label ul li {
  margin-right: 5px;
  display: inline;
}

#menu-showhide {
  margin: 10px;
}

.side-dash {
  width: 230px;
  margin: 10px;
}

.side-dash h3 {
  padding: 10px;
  font-size: 12px;
  color: #fff !important;
  margin-bottom: 10px !important;
}

.side-dash h3 span {
  text-align: center;
  background: #0003;
  border-radius: 24px;
  margin-left: -10px;
  padding: 4px 12px;
}

.side-dashh-list, .side-bar-list {
  margin-bottom: 25px;
  margin-left: -30px;
}

.side-dashh-list li, .side-bar-list li {
  font-size: 12px !important;
  line-height: 1.6em !important;
}

.side-dashh-list span {
  padding-right: 15px;
  float: right !important;
}

.side-dashh-list span i {
  margin-left: 8px;
  left: 0 !important;
}

.side-bar-list li {
  font-size: 12px !important;
  line-height: 4em !important;
}

.linebar, .linebar2, .linebar3 {
  float: right;
  margin-right: 10px;
}

.scroll {
  float: left;
  width: 21%;
  margin-top: 40px;
  margin-left: 4%;
  padding: 0;
}

.topnav {
  padding: 40px 28px 25px 0;
}

img.icon-menu {
  margin-top: 3px;
  margin-left: 10px;
  margin-right: 3px;
}

ul.topnav {
  margin: 0;
  padding: 0;
  font-size: 14px;
  box-shadow: 0 1px #0000001a;
  line-height: .5em !important;
  list-style: none !important;
}

ul.topnav li a {
  text-transform: capitalize;
  padding: 10px 10px 10px 30px;
  font-size: 13px;
  line-height: 25px;
  text-decoration: none;
  list-style: none;
  display: block;
  color: #fff !important;
}

.menucon-main {
  color: red !important;
}

.ui-tooltip, .arrow:after {
  background: #a6a7b3 !important;
  border: 0 solid #fff !important;
}

.ui-tooltip {
  border-radius: 20px;
  margin-top: -80px;
  padding: 5px 10px;
  color: #fff !important;
  text-transform: none !important;
  box-shadow: none !important;
  font: 10px Helvetica Neue, sans-serif !important;
}

.arrow {
  width: 70px;
  height: 16px;
  margin-left: -35px;
  position: absolute;
  bottom: -16px;
  left: 50%;
  overflow: hidden;
}

.arrow.top {
  top: -16px;
  bottom: auto;
}

.arrow.left {
  left: 20%;
}

.arrow:after {
  content: "";
  width: 25px;
  height: 25px;
  tranform: rotate(45deg);
  position: absolute;
  top: -20px;
  left: 20px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  box-shadow: none !important;
}

.arrow.top:after {
  top: auto;
  bottom: -20px;
}

ul.topnav li a:hover {
  text-shadow: none;
  background-color: #0003;
  border-left: 2px solid #fff;
  padding-left: 28px;
  position: relative;
  color: #fff !important;
}

ul.topnav li a:hover:after {
  width: 0;
  height: 0;
  content: "";
  tranform: rotate(90deg);
  border-bottom: 6px solid #fff;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  display: block;
  position: absolute;
  top: 19px;
  left: -6px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

ul.topnav ul {
  margin: 0;
  padding: 0;
  display: none;
}

ul.topnav ul li {
  clear: both;
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.topnav ul li a {
  background: #0000004d;
  outline: 0;
  padding-left: 30px;
  font-size: 13px;
  font-weight: normal;
  color: #ffffffe6 !important;
}

ul.topnav ul li a:hover {
  background-color: none repeat scroll 0 0 #0003;
  color: #fff !important;
}

ul.topnav ul ul li a {
  color: #444;
  background: #252d3b;
  padding-left: 40px;
  transition: all .2s;
}

ul.topnav ul ul li a:hover {
  color: #675c7c;
  background-color: #d3ceb8;
}

ul.topnav h4 {
  margin-top: -2px;
  float: right !important;
}

.nest-bg {
  margin-top: 40px;
}

.dahboard-gauge {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 25px 0 0;
  padding: 5px;
  position: relative;
  box-shadow: 0 5px 5px #0003;
}

.dahboard-gauge:after, .dahboard-gauge:before {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: -moz-use-text-color #ccc #ccc;
  content: "";
  height: 1px;
  background-color: #fff;
  border-style: none solid solid;
  border-width: 0 1px 1px;
  border-image: ;
  border-radius: 0 0 3px 3px;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 3px;
  right: 3px;
}

.dahboard-gauge:before {
  bottom: -5px;
  left: 6px;
  right: 6px;
}

.navbar-inverse .navbar-inner {
  box-shadow: none !important;
  background: #20262e !important;
  border: 0 solid #fff !important;
}

.navbar-color {
  border-top: 5px solid #0f1d2f;
  border-bottom: 4px solid #fff;
}

.noft-pos {
  z-index: 2147483647;
  right: 0;
  position: absolute !important;
  top: -10px !important;
}

.badge {
  border-radius: 9px;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.navbar .brand {
  letter-spacing: -2px;
  text-transform: uppercase;
  font-size: 22px !important;
}

.navbar .brand span {
  text-transform: uppercase;
  letter-spacing: -2px;
  font-weight: bold;
}

.navbar .brand i {
  color: #f4f4f4;
  letter-spacing: 0;
  background-color: #0000004d;
  border-radius: 2px;
  padding: 2px 3px;
  font-size: 9px;
  font-style: normal;
  position: relative;
  top: -4px;
  right: -7px;
}

.devider-nav-top {
  width: 2px;
  height: 60px;
  border-right: 1px solid #0000004d;
  margin: 0 7px 0 0;
  display: block;
}

.btn-wigdet, .input-widget {
  margin: -7px 25px 0 0 !important;
}

.margin-iconic {
  margin-right: 10px;
}

.wrapper {
  height: 200px;
  width: 300px;
  background: #fff;
}

#theme-drawer {
  color: #000;
  float: right;
}

#sidePanel {
  width: 500px;
  position: fixed;
  top: 0;
  right: -220px;
}

#panelHandle {
  cursor: pointer;
  float: left;
  height: 26px;
  width: 26px;
  background: #000;
  border-radius: 5px 0 0 5px;
  padding: 7px;
  position: relative;
  top: 150px;
  left: -320px;
}

#panelContent {
  float: left;
  width: 250px;
  height: 600px;
  background-color: #fffc;
  border-radius: 0;
  padding: 80px 15px 0;
}

#panelContent h4 {
  color: #000;
}

#panelHandle p {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  position: relative;
  top: 26px;
  right: -4px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
}

.wrap-pagination {
  height: 50px;
  width: 100%;
  background: #fff;
  position: fixed;
  top: 61px;
}

.breadcrumb {
  z-index: 99;
  background-color: #f5f5f5 !important;
  border-radius: 0 !important;
  margin: 0 0 0 15px !important;
  padding: 0 !important;
  list-style: none !important;
}

.breadcrumb li {
  display: inline-block;
  text-shadow: none !important;
}

.breadcrumb li a {
  font-size: 13px;
  transition: all .4s;
  color: #9ea7b3 !important;
}

.breadcrumb li a:hover {
  text-decoration: none;
  color: #6adae4 !important;
}

.nav-date-picker {
  cursor: pointer;
  padding-left: 20px;
}

.datepicker-headline {
  font-style: normal;
  margin-right: 15px !important;
}

.datepicker-wrap {
  position: absolute;
  right: 84px;
}

#breadcrumb {
  width: 100%;
  background: #00000005;
  border-bottom: 1px solid #0000000a;
  margin: 0;
  padding: 15px 0 5px 20px;
  overflow: hidden;
}

#breadcrumb li {
  float: left;
  padding-left: 10px;
  list-style-type: none;
}

#breadcrumb a {
  height: 30px;
  color: #9ea7b3;
  background-position: 100%;
  background-repeat: no-repeat;
  padding-right: 0;
  text-decoration: none;
  display: block;
}

.home {
  border: none;
  margin: 8px 0;
}

.form_center {
  width: 70%;
  margin: 0 auto;
}

.top-val {
  margin-bottom: 20px;
}

.put-dash {
  background-color: #0000000d !important;
}

.tittle-content {
  height: 40px;
  border-bottom: 1px solid #0003;
  padding: 15px 0 0;
  display: none;
  box-shadow: 0 1px #fff;
}

.tittle-content-header {
  text-transform: uppercase;
  margin: 0 0 0 60px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: normal;
  color: #9ea7b3 !important;
}

.tittle-content-header > span > i {
  text-transform: capitalize;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
}

.tittle-middle-header {
  margin: -17px 0;
  padding: 1px 0;
}

.tittle-alert {
  color: #4ac4bc;
}

.nest {
  clear: both;
  background: #fff;
  border-radius: 3px;
  position: relative;
}

.nest .title {
  height: 50px;
  background-color: #595f69;
  border-radius: 4px 4px 0 0;
}

.nest .title h6 {
  color: #f1f1f1;
  float: left;
  text-transform: uppercase;
  padding: 0 12px;
  font-family: Open Sans Condensed, serif;
  font-size: 20px;
  font-weight: normal;
  position: relative;
  top: 4px;
}

.slidder-bar-nest {
  margin-bottom: 30px;
  padding: 15px;
}

.nest .title .titleIcon {
  color: #fff;
  float: left;
  height: 30px;
  width: 21px;
  background: #323a45;
  border-radius: 3px 0 0;
  margin: 0;
  padding: 13px 12px 7px 18px;
  font-size: 18px;
}

.nest .title .titleToggle {
  float: right;
  border-radius: 3px 0 0;
  margin: 14px 5px 0 0;
  font-size: 24px;
}

.nav-toggle {
  color: #fff;
  position: relative;
}

.nav-toggle:hover {
  color: #323a45;
}

.nest .title .titleClose {
  float: right;
  border-radius: 3px 0 0;
  margin: 14px 12px 0 0;
  font-size: 24px;
}

.gone {
  color: #0003;
  position: relative;
}

.gone:hover {
  color: #00000080;
}

.fullexe {
  color: #595f69;
  background: #fff;
  border-radius: 3px;
  padding: 1px 5px;
  font-size: 12px;
  position: relative;
  top: -4px;
  right: -7px;
}

.fullexe:hover {
  color: #fff;
  background: #323a45;
}

.body-nest {
  background: #fff;
  border-radius: 0 0 4px 4px;
  padding: 15px;
}

.page-extra {
  background: #fff;
  border-radius: 4px;
  margin: 30px 0 0;
  padding: 15px;
}

.page-error {
  width: 50%;
  z-index: 5000;
  margin: 0 auto;
  padding: 15px;
  position: relative;
}

img.sidebar-icon {
  float: left;
  margin: 0 6px 0 0;
}

.copyright {
  color: #000;
  position: fixed;
  bottom: 5px;
  right: 10px;
  font-size: 11px !important;
}

.nest .title-alt {
  height: 50px;
  border-bottom: 1px dotted #ddd;
  border-radius: 4px 4px 0 0;
  margin: 30px auto 0;
}

.nest .title-alt h6 {
  color: #9ea7b3;
  float: left;
  text-transform: uppercase;
  padding: 0 5px 2px;
  font-family: Open Sans, serif;
  font-size: 14px;
  font-weight: normal;
  position: relative;
  top: 6px;
  left: 15px;
}

.nest .title-alt .titleIcon {
  color: #9ea7b3;
  float: left;
  height: 30px;
  width: 21px;
  background: #323a45;
  border-radius: 3px 0 0;
  margin: 0;
  padding: 13px 12px 7px 18px;
  font-size: 18px;
}

.nest .title-alt .titleToggle {
  float: right;
  border-radius: 3px 0 0;
  margin: 14px 10px 0 0;
}

.nest .title-alt .titleToggle .nav-toggle-alt {
  color: #ddd;
  font-size: 16px;
  position: relative;
}

.nest .title-alt .titleToggle .nav-toggle-alt:hover {
  color: #3498db;
}

.nest .title-alt .titleClose {
  float: right;
  border-radius: 3px 0 0;
  margin: 14px 15px 0 0;
}

.nest .title-alt .titleClose .gone {
  color: #ddd;
  font-size: 16px;
  position: relative;
}

.nest .title-alt .titleClose .gone:hover {
  color: #ff0064;
}

.nest .title-alt .titleFull {
  float: right;
  border-radius: 3px 0 0;
  margin: 14px 12px 0 0;
  font-size: 24px;
  display: none;
}

.nest .title-alt .titleFull .fullexe {
  color: #ddd;
  background: none;
  padding: 0;
  font-size: 19px;
  position: relative;
  top: 0;
  right: -5px;
}

.nest .title-alt .titleFull .fullexe:hover {
  color: #6adae4;
}

.direction {
  margin-top: -20px;
  text-decoration: none;
}

.direction li {
  margin: 20px 0;
  text-decoration: none;
  list-style: none;
}

.direction-icon {
  color: #fff;
  float: left;
  height: 55px;
  text-align: center;
  width: 55px;
  border-radius: 50%;
  font-size: 34px;
  line-height: 50px;
  position: relative;
  left: -25px;
}

.direction p {
  margin: 0;
}

.direction h3 {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.direction h3 span {
  color: #fff;
  text-transform: uppercase;
  background: #a8bdcf;
  border-radius: 3px;
  padding: 0 10px;
  font-size: 12px;
  position: relative;
  top: -3px;
}

.direction .divider {
  height: 1px;
  width: 100%;
  background: #f1f1f1;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  left: 0;
  margin-top: -12px !important;
}

.armada > span {
  color: #fff;
  text-transform: uppercase;
  background: #ddd;
  border-radius: 0 10px 10px 0;
  padding: 1px 10px;
  position: relative;
  top: -5px;
  left: -5px;
}

.armada > p {
  font-weight: bold;
}

.armada i {
  text-transform: lowercase;
  font-size: 11px;
  font-weight: normal;
}

.armada-devider {
  width: 23%;
  border-right: 1px dashed #ddd;
  padding-top: 20px !important;
}

.driver-devider {
  width: 40%;
  padding-top: 15px !important;
}

.driver-devider > h3 {
  float: left;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.driver-devider > p {
  float: left;
  margin: 0 0 0 20px;
  position: relative;
  left: -20px;
}

.armada-pic {
  float: left;
  width: 52px;
  border: 4px solid #0000001a;
  margin-top: -5px;
  margin-right: 19px;
  display: inline-block;
}

.progress-devider span {
  margin-top: 0;
}

.progress-bar-plane {
  background-color: #ab6db0;
}

.chart-wrap {
  min-height: 307px;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 30px;
  padding: 280px 20px 20px;
  position: relative;
}

.chart-dash {
  min-height: 200px;
  width: 100%;
  z-index: 0;
  background: #45b6b0;
  border-radius: 4px 4px 0 0;
  padding: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.weather-wrap {
  color: #fff;
  min-height: 307px;
  background-color: #65c3df;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 4px;
  margin-top: 30px;
  position: relative;
}

.berlin {
  clip: rect(0px, auto, 80%, 0px);
  width: 100%;
  height: 65%;
  border-radius: 4px 4px 0 0;
  position: absolute;
}

.temperature {
  z-index: 1;
  margin: 20px;
  font-size: 21px;
}

.temperature span {
  float: right;
}

.weather-icon {
  z-index: 1;
  margin: 70px 20px 0;
}

.weather-icon i {
  font-size: 90px;
}

.weather-text > h3 {
  float: right;
  letter-spacing: -8px;
  padding: 0 25px;
  font-size: 57px;
  position: relative;
}

.weather-text > h3 > i {
  margin: 0 10px;
  font-size: 47px;
}

.weather-text > h2 {
  letter-spacing: -1px;
  padding: 0 25px;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  top: 30px;
}

.weather-text > h2 > i {
  font-size: 14px;
  font-style: normal;
  position: relative;
  top: -15px;
}

.weather-dash {
  background: #fff3;
  padding: 5% 20px;
}

.daily-weather {
  text-align: center;
}

.daily-weather > h2 {
  text-align: center;
  text-transform: uppercase;
  background: #0003;
  border-radius: 3px;
  margin: 0;
  padding: 5px 0;
  font-size: 14px;
}

.daily-weather > h3 {
  text-align: center;
  text-transform: uppercase;
  margin: 10px 0;
  font-size: 30px;
  font-weight: bold;
}

.daily-weather h3 span {
  font-size: 15px;
}

.daily-weather > h4 {
  text-align: center;
  text-transform: uppercase;
  margin: 10px 0;
  font-size: 30px;
}

.daily-weather > h5 {
  text-align: center;
  text-transform: uppercase;
  margin: 15px 0;
  font-size: 18px;
  font-weight: normal;
}

.daily-weather > h5 > p {
  font-size: 12px;
}

.traffic > h2 {
  margin: 0;
  font-size: 14px;
}

.traffic > h1 {
  color: #45b6b0;
  margin: 5px 0;
  font-size: 35px;
  font-weight: 600;
}

.speed > h2 {
  margin: 0;
  font-size: 14px;
}

.speed > h1 {
  color: #45b6b0;
  margin: 5px 0;
  font-size: 50px;
  font-weight: 600;
}

.speed > h1 > span {
  color: #fff;
  text-transform: uppercase;
  background: #45b6b0;
  border-radius: 3px;
  margin: 0;
  padding: 0 5px;
  font-size: 11px;
  font-weight: normal;
  position: relative;
  left: -5px;
}

.traffic-rates > h4 {
  color: #fff;
  text-align: center;
  background: #45b6b0;
  border-radius: 4px;
  margin-top: 25px;
  padding: 8px 0;
  font-size: 14px;
  font-weight: normal;
}

.traffic-rates > h1 {
  color: #45b6b0;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}

.traffic-rates > h1 > span {
  font-size: 11px;
}

.tooltip-examples {
  margin: 0;
  padding: 0;
}

.tooltip-examples li {
  display: inline;
}

.tooltip-examples li a {
  color: #666;
  cursor: pointer;
  margin-left: 10px;
  text-decoration: none;
}

.check-box-list {
  margin: 0;
  padding: 0;
}

.check-box-list li {
  margin: 0 20px 0 0;
  text-decoration: none;
  display: inline-block;
}

.list {
  float: left;
  margin: 0;
  padding-right: 60px;
}

.list li {
  list-style: none;
  position: relative;
}

.list label {
  position: relative;
  top: -20px;
  right: -26px;
}

.warning-color {
  color: #fff !important;
  background: #595f69 !important;
}

.dash-ico {
  font-size: 45px;
}

.chat-left {
  width: 80%;
  color: #fff;
  background: #1abc9c;
  border-radius: 5px;
  padding: 12px;
  position: relative;
  left: 22px;
}

.chat-left:after {
  content: "";
  width: 0;
  z-index: 1;
  border: 13px solid #0000;
  border-left: 0 solid #1abc9c;
  border-right: 15px solid #1abc9c;
  margin-top: -13px;
  display: block;
  position: absolute;
  top: 30px;
  left: -15px;
}

.chat-right {
  width: 80%;
  color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  background: #3498db;
  border-radius: 5px;
  padding: 12px;
  position: relative;
  left: 0;
}

.chat-right:after {
  content: "";
  width: 0;
  z-index: 1;
  border: 13px solid #0000;
  border-left: 15px solid #3498db;
  border-right: 0 solid #3498db;
  margin-top: -13px;
  display: block;
  position: absolute;
  top: 30px;
  right: -15px;
}

.media-heading {
  border-bottom: 1px dotted #ffffff80;
  margin: 0 0 6px;
  padding: 0 0 5px;
  font-size: 14px;
}

.message-form {
  background: #f8f8f8;
  margin: 20px 0 0;
}

.graphControls {
  margin: 0 0 0 20px;
}

.gauge {
  text-align: center;
  margin: 0 auto;
}

.fullscreen {
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: inherit;
  background-color: #fff;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.body-flow {
  overflow-y: hidden;
}

.body-flow-scroll {
  overflow-y: auto !important;
}

.profit {
  color: #0006;
  z-index: 0;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 15px;
  position: relative;
}

.profit .headline {
  color: #fff;
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.profit .headline h3 {
  text-transform: uppercase;
  margin: 0;
  padding: 15px;
  font-size: 15px;
  font-weight: normal;
}

.profit .headline h3 span {
  background-color: #ff6b6b;
  border-radius: 20px;
  padding: 0 10px;
}

.profit .headline h3 i {
  margin-left: 5px;
  font-style: normal;
}

.profit .value {
  letter-spacing: -3px;
  text-align: center;
  color: #ff6b6b;
  border-radius: 6px;
  margin: 20px auto 0;
  font: 5em Open Sans;
  position: relative;
}

.profit .value span {
  margin-right: 10px;
  font-size: 60px;
  position: relative;
  top: 0;
}

.start-min {
  text-indent: 0 !important;
  font-size: 30px !important;
}

.clock-position {
  position: relative;
  right: -6px;
  top: -20px !important;
}

.getting-started {
  position: relative;
  left: -20px;
}

.profit .value i {
  letter-spacing: 0;
  text-transform: uppercase;
  margin: 0 0 0 10px;
  font-size: 27px;
  font-style: normal;
}

.profit .value b {
  letter-spacing: 0;
  text-transform: uppercase;
  margin: 0 0 0 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}

.profit .profit-line {
  text-align: center;
}

.revenue {
  color: #0006;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 15px;
  position: relative;
}

.revenue .headline {
  color: #fff;
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.revenue .headline h3 {
  text-transform: uppercase;
  margin: 0;
  padding: 15px;
  font-size: 15px;
  font-weight: normal;
}

.revenue .headline h3 span {
  background-color: #ffa200;
  border-radius: 20px;
  padding: 0 5px;
}

.revenue .headline h3 i {
  margin-left: 5px;
  font-style: normal;
}

.revenue .value {
  letter-spacing: -3px;
  text-align: center;
  color: #ffa200;
  border-radius: 6px;
  margin: 20px auto 0;
  font: 5em Open Sans;
  position: relative;
}

.revenue .value span {
  margin-right: 5px;
  font-size: 25px;
  position: relative;
  top: -20px;
}

.revenue .profit-line {
  text-align: center;
}

.revenue .value i {
  letter-spacing: -1px;
  text-transform: uppercase;
  margin: 40px 20px 0 0;
  font-size: 27px;
  font-style: normal;
}

b#speed {
  font-size: 60px;
  font-weight: normal;
}

.gauge-position {
  position: relative;
  right: -20px;
  top: 30px !important;
}

.order {
  color: #0006;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 15px;
  position: relative;
}

.order .headline {
  color: #fff;
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.order .headline h3 {
  text-transform: uppercase;
  margin: 0;
  padding: 15px;
  font-size: 15px;
  font-weight: normal;
}

.order .headline h3 span {
  background-color: #45b6b0;
  border-radius: 20px;
  padding: 0 10px;
}

.order .headline h3 i {
  font-style: normal;
}

.order .value {
  letter-spacing: -3px;
  text-align: center;
  color: #45b6b0;
  border-radius: 6px;
  margin: 20px auto 0;
  font: 5em Open Sans;
  position: relative;
}

.order .value span {
  margin-right: 5px;
  font-size: 25px;
  position: relative;
  top: -20px;
}

.order .profit-line {
  text-align: center;
}

.order .value i {
  letter-spacing: -1px;
  text-transform: uppercase;
  margin: 0 0 0 10px;
  font-size: 27px;
  font-style: normal;
}

.order .value b {
  letter-spacing: -1px;
  text-transform: uppercase;
  margin: 0 0 0 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}

.member {
  color: #0006;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 15px;
  position: relative;
}

.member .headline {
  color: #fff;
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.member .headline h3 {
  margin: 0;
  padding: 15px;
  font-size: 15px;
  font-weight: normal;
}

.member .headline h3 span {
  background-color: #65c3df;
  border-radius: 20px;
  padding: 0 10px;
}

.member .headline h3 i {
  font-style: normal;
}

.member .value {
  letter-spacing: -3px;
  text-align: center;
  color: #65c3df;
  border-radius: 6px;
  margin: 20px auto 0;
  font: 5em Open Sans;
  position: relative;
}

.member .value span {
  margin-right: 5px;
  font-size: 25px;
  position: relative;
  top: -20px;
}

.member .value b {
  letter-spacing: 0;
  text-transform: uppercase;
  margin: 0 0 0 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}

.member .profit-line {
  text-align: center;
  font-size: 12px;
}

.member .value i {
  letter-spacing: -1px;
  margin: 0 0 0 10px;
  font-size: 27px;
  font-style: normal;
}

.profit .headline .titleClose, .revenue .headline .titleClose, .order .headline .titleClose, .member .headline .titleClose {
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 17px;
}

.paper-wrap {
  background-color: none repeat scroll 0 0 #fff;
  background-position: -1px 0;
  background: linear-gradient(45deg, transparent 20px, $ color-green 20px), linear-gradient(135deg, transparent 20px, $ color-green 20px), linear-gradient(225deg, transparent 20px, $ color-green 20px), linear-gradient(315deg, transparent 20px, $ color-green 20px);
  min-height: 1430px;
  background-position: 0 100%, 0 0, 100% 0, 100% 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.bevel.tlbr {
  background: -moz-linear-gradient(45deg, #f5f5f5 30px 30px), -moz-linear-gradient(135deg, #0000 30px, #f5f5f5 30px), -moz-linear-gradient(225deg, #f5f5f5 30px 30px), -moz-linear-gradient(315deg, #0000 30px, #f5f5f5 30px);
  background: -o-linear-gradient(45deg, #f5f5f5 30px 30px), -o-linear-gradient(135deg, #0000 30px, #f5f5f5 30px), -o-linear-gradient(225deg, #f5f5f5 30px 30px), -o-linear-gradient(315deg, #0000 30px, #f5f5f5 30px);
  background: -webkit-linear-gradient(45deg, #f5f5f5 30px 30px), -webkit-linear-gradient(135deg, #0000 30px, #f5f5f5 30px), -webkit-linear-gradient(225deg, #f5f5f5 30px 30px), -webkit-linear-gradient(315deg, #0000 30px, #f5f5f5 30px);
}

.bevel.all, .bevel.trbl, .bevel.tlbr, .bevel.tr {
  -moz-background-size: 52% 52%;
  -webkit-background-size: 52% 52%;
  background-position: 0 100%, 100% 100%, 100% 0, 0 0;
  background-repeat: no-repeat;
  background-size: 52% 52%;
}

.paper-wrap:before {
  content: "";
  filter: progid: DXImageTransform. Microsoft. BasicImage(rotation= 3);
  border-top: 0 solid #fff;
  border-bottom: 42px solid #fff;
  border-left: 0 solid #0000001a;
  border-right: 42px solid #0000001a;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  box-shadow: 0 0 #0006, 0 0 10px #0000004d;
}

.content-wrap {
  padding: 0 30px;
}

#paper-top {
  height: 40px;
  border-bottom: 1px solid #ddd;
  padding: 0;
}

.devider-vertical {
  height: 74px;
  width: 3px;
  border-right: 1px dashed #ccc;
  position: absolute;
  top: -35px;
  left: 0;
}

#paper-middle {
  height: 400px;
  width: 100%;
  background: #e0e8ef;
  border-bottom: 1px solid #ddd;
  margin-top: 0;
  margin-bottom: 30px;
}

.gauge-wrap li {
  list-style: none;
  display: inline-block;
}

#sheetswitch {
  border-bottom: 1px solid #23b2d2;
  border-left: 1px solid #23b2d2;
  border-right: 1px solid #23b2d2;
  padding: 10px 5px;
  position: absolute;
  top: 0;
  right: 20px;
}

#sheetswitch img {
  border: none;
}

#sheetswitch .swatch {
  width: 16px;
  height: 16px;
  float: left;
  border: 1px solid #fff;
  margin-right: 15px;
  display: block;
}

#sheetswitch .swatch_hi {
  border: 1px solid #777;
}

.sheetswitch_prev {
  margin-right: 15px;
}

.sheetswitch_next, .sheetswitch_prev {
  float: left;
}

.wrap-footer {
  margin-top: 60px;
}

.footer-space {
  height: 60px;
  width: 100%;
}

#footer {
  height: 50px;
  width: 100%;
  background: #f8f8f8;
  border-top: 1px solid #ddd;
  position: absolute;
  bottom: 0;
  left: 0;
}

#footer ul {
  float: right;
  margin: 5px 15px;
  text-decoration: none;
}

#footer ul li {
  color: #555;
  padding: 0 6px;
  text-decoration: none;
  display: inline-block;
  font-size: 14px !important;
}

.copyright {
  color: #666;
  float: right;
  padding: 5px 15px;
  position: relative;
  bottom: 0;
  font-size: 12px !important;
}

.devider-footer {
  float: right;
  height: 50px;
  width: 4px;
  border-left: 1px solid #ddd;
  margin-top: -10px;
}

.devider-left {
  float: left;
  height: 50px;
  width: 4px;
  border-left: 1px solid #ddd;
}

@media (max-width: 767px) {
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    min-height: 1px;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
  }

  #paper-top {
    height: auto;
    padding: 0 20px 0 30px !important;
  }

  .tittle-content-header {
    margin: 0 0 0 30px !important;
  }

  #skin-select #toggle {
    display: none !important;
  }

  #skin-select {
    top: 31px !important;
    left: -250px !important;
  }

  #menu-showhide, .menu-left-nest {
    border-radius: 0 !important;
    margin: 1px 0 0 !important;
  }

  .side-bar {
    margin-top: -29px !important;
    display: block !important;
  }

  .navbar-top {
    margin: 0 10px !important;
  }

  .wrap-fluid {
    float: right !important;
    width: 100% !important;
  }

  #skin-select li {
    text-align: right !important;
  }

  #skin-select li span, ul.topnav h4, .noft-blue, .noft-blue-number, .noft-purple-number, .dark, .side-dash, .dropdown-wrap:after, .dropdown-setting:after, .widget-menu-hide, .config-wrap {
    display: none !important;
  }

  body {
    padding-left: 50px !important;
  }

  .tooltip-tip2, .tooltip-tip {
    display: block !important;
  }

  #nt-title-container {
    margin: 15px 0 20px !important;
  }

  .nav.navbar-nav.navbar-right {
    margin: 35px 0 !important;
  }

  .navbar {
    margin-left: 0 !important;
  }

  .container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin: 0 !important;
  }

  .dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #0003 !important;
  }

  .dropdown-menu.dropdown-wrap div {
    width: auto !important;
  }

  .dropdown-menu > li > a {
    color: #fff !important;
  }

  .running-text {
    width: 100% !important;
    height: 40px !important;
    padding: 10px 45px 0 20px !important;
  }

  .navbar-collapse {
    background: #0006 !important;
  }

  .noft-red, .noft-green {
    float: right;
    margin-right: 20px;
    position: relative !important;
    top: 0 !important;
  }

  .navbar-nav .open .dropdown-menu {
    float: none;
    width: auto;
    box-shadow: none;
    background-color: #0000;
    border: 0;
    margin-top: 0;
    position: static;
    border: 0 solid #ccc !important;
    margin-left: 0 !important;
  }
}

.right-wrapper {
  min-height: 300px;
  background: #0003;
  border-radius: 4px;
  margin: 75px 10px 10px;
  padding: 20px 15px;
}

.right-wrapper h3 {
  color: #fff;
  margin: 0 0 20px 20px;
  font-size: 13px;
}

.right-wrapper h3 i {
  font-style: normal;
}

.right-wrapper h3 span {
  color: #fff;
  background: #0000001a;
  border-radius: 11px;
  margin: 0 0 20px;
  padding: 2px 10px 4px;
  font-size: 13px;
  font-weight: normal;
}

.chat {
  margin: 10px 0 0 -19px;
}

.chat li {
  margin-bottom: 0;
  list-style: none;
  display: block;
}

.chat li a {
  color: #fff;
  text-decoration: none;
}

.chat li a span {
  width: 22px;
  height: 22px;
}

.chat li b {
  font-weight: normal;
  position: relative;
  top: -5px;
}

.chat li i {
  color: #ffffff4d;
  margin: 0 0 0 30px;
  font-size: 10px;
  position: relative;
  top: -18px;
}

img.img-chat {
  width: 30px;
  border: 1px solid #0003;
  margin: 0 10px 0 -10px;
}

.img-offline {
  filter: gray;
  -webkit-filter: grayscale();
}

.label-chat {
  margin-bottom: 25px !important;
  font-size: 11px !important;
}

.widget-knob {
  float: left;
  width: 60px;
  margin-bottom: -15px;
  display: block;
}

.widget-def {
  float: left;
  width: 100%;
  display: inline-block;
  position: relative;
  top: -35px;
  left: 65px;
}

.widget-def b {
  color: #fff;
  text-transform: capitalize;
  float: left;
  font-size: 12px;
  font-weight: normal;
  position: relative;
  top: 0;
  left: 0;
}

.widget-def i {
  color: #fff;
  float: left;
  color: #ffffff4d;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

button.navbar-toggle.toggle-menu-mobile {
  position: absolute;
  right: 40px;
}

.invoice-header {
  color: #fff;
  background: #efeff0;
  border-radius: 4px 4px 0 0;
  margin: -16px 0 30px;
}

small.pull-right {
  color: #fff;
  margin: 8px 0;
  font-size: 17px;
}

.page-header {
  border-bottom: 0 solid #eee !important;
  margin: 30px 0 20px !important;
  padding-bottom: 9px !important;
}

.number-pb {
  height: 10px;
  background-color: #ddd;
  margin: 10px 0;
  position: relative;
}

.number-pb .number-pb-shown {
  height: 11px;
  background-color: #65c3df;
  position: absolute;
  top: -1px;
  left: 0;
}

.number-pb .number-pb-num {
  min-width: 20px;
  background-color: #fff;
  padding: 0 5px;
  position: absolute;
  top: -.45em;
  left: 0;
}

.number-pb-shown.dream {
  background: #45b6b0;
}

.number-pb-shown.sun {
  background: #ff6b6b;
}

.modal-background.svelte-1k3utew {
  width: 100%;
  height: 100%;
  background: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
}

.modal.svelte-1k3utew {
  width: calc(100vw - 4em);
  max-width: 32em;
  max-height: calc(100vh - 4em);
  background: #fff;
  border-radius: .2em;
  padding: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
}

/*# sourceMappingURL=index.bf750913.css.map */
